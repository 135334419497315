// src/ScanQRCode.js
import React from 'react';
import { Html5QrcodeScanner } from "html5-qrcode";

class ScanQRCode extends React.Component {
  componentDidMount() {
    const scanner = new Html5QrcodeScanner("reader", { fps: 10, qrbox: 250 });
    scanner.render(this.props.onScanSuccess, this.props.onScanFailure);
  }

  render() {
    return <div id="reader" style={{ width: "300px" }}></div>;
  }
}

export default ScanQRCode;
