// src/GenerateQRCode.js
// src/GenerateQRCode.js
import React from 'react';
import QRCode from 'qrcode.react';

const GenerateQRCode = ({ token }) => (
  <div>
    <QRCode value={token} />
  </div>
);

export default GenerateQRCode;

