// src/App.js
import React, { useState } from 'react';
import GenerateQRCode from './GenerateQRCode';
import ScanQRCode from './ScanQRCode';

function App() {
  const [token] = useState('unique-token-value');

  const handleScanSuccess = (decodedText, decodedResult) => {
    console.log(`Code matched = ${decodedText}`, decodedResult);
    // Send token to backend for validation
  };

  const handleScanFailure = (error) => {
    console.warn(`QR code scan error = ${error}`);
  };

  return (
    <div className="App">
      <h1>Web Chappy</h1>
      <GenerateQRCode token={token} />
      <ScanQRCode onScanSuccess={handleScanSuccess} onScanFailure={handleScanFailure} />
    </div>
  );
}

export default App;

